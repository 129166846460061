<template>
  <div class="result-container">
    <img class="icon" :src="icon">
    <div class="title">{{title}}</div>
    <div class="desc">{{desc}}</div>
    <div class="button" @click="backHome">返回首页</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    icon: {
      type: String,
      default: "/static/img/mobile/success.png",
    },
    title: {
      type: String,
      default: "提交成功",
    },
    desc: {
      type: String,
      default: "稍后筒筒会与您联系，请保持电话通畅。",
    },
  },
  methods: {
    backHome() {
      this.$router.push({ path: "/mobile/home" });
    },
  },
};
</script>
<style lang="scss" scoped>
.result-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    padding-top: 657px;
    width: 192px;
    height: 192px;
  }

  .title {
    margin-top: 38px;
    font-size: 73px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 119px;
  }

  .desc {
    margin-top: 12px;
    font-size: 58px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 119px;
  }

  .button {
    position: absolute;
    bottom: 0;
    margin-bottom: 384px;

    width: 883px;
    height: 173px;
    background: linear-gradient(180deg, #ffa83a 0%, #ff6f1b 100%);
    border-radius: 86px;
    border: 1px solid rgba($color: #ff6f1b, $alpha: 0);

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 58px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 81px;

    transition: 0.5s;

    &:hover {
      color: #ff6f1b;
      border: 1px solid #ff6f1b;
      background: none;
    }
  }
}
</style>